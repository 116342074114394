import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {ApiService} from "./api.service";
@Injectable({
  providedIn: 'root'
})
export class AuthModalService {
  isOpen$ = new BehaviorSubject(false);
  type$ = new BehaviorSubject<'reg' | 'auth' | 'code' | 'recovery'>('auth');
  isOpenRecoveryPasswordModal$ = new BehaviorSubject('');
  constructor(private apiService: ApiService) {
  }

  close() {
    this.isOpen$.next(false);
    this.isOpenRecoveryPasswordModal$.next('');
    this.type$.next('auth');
  }
}
