<div class="field-input" *ngIf="lang === 'ru'">
  <div class="label label-inner">Телефон</div>
  <input
    #input
    type="text"
    class="input"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [maskito]="options"
    autocomplete="off"
  />
</div>
<div class="field-input" *ngIf="lang !== 'ru'">
  <div class="label label-inner">Телефон</div>
  <input
    #input
    type="text"
    class="input"
    [placeholder]="placeholder"
    [formControl]="formControl"
    autocomplete="off"
  />
</div>
<ng-container *ngIf="formControl.errors && formControl.touched">
  <div class="errors" *ngIf="formControl.errors && formControl.errors['required']">
    <div class="error">{{ 'errors.required' | translate }}</div>
  </div>
  <div class="errors" *ngIf="formControl.errors && formControl.errors['invalidPhoneNumberLength']">
    <div class="error">{{ 'errors.invalidPhoneNumberLength' | translate }}</div>
  </div>
</ng-container>
