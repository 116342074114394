import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, Self} from '@angular/core';
import {UiModule} from "../../ui/ui.module";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {BehaviorSubject, Observable, takeUntil} from "rxjs";
import {DestroyService} from "../../service/destroy.service";

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    UiModule,
    RouterLink,
    NgForOf,
    TranslateModule,
    NgIf
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
  providers: [DestroyService]
})
export class PaginationComponent implements OnInit {
  @Input() totalItems: number = 0;  // Общее количество элементов
  @Input() itemsPerPage: number = 1;  // Количество элементов на странице
  @Input() currentPage$ = new BehaviorSubject(1);  // Текущая страница
  @Input() maxPagesToShow: number = 5;  // Количество страниц для отображения
  @Output() pageChanged = new EventEmitter<number>();
  @Output() pageMore = new EventEmitter<number>();

  get currentPage() {
    return this.currentPage$.getValue();
  }
  set currentPage(value) {
    this.currentPage$.next(value);
  }
  totalPages: number = 0;  // Общее количество страниц
  pages: number[] = [];  // Список страниц для отображения

  constructor(@Self() @Inject(DestroyService) private destroy$: Observable<void>) {
  }

  ngOnInit(): void {
    this.calculateMaxPagesToShow();
    this.updatePages();
    this.currentPage$.pipe(takeUntil(this.destroy$)).subscribe(page => {
      this.updatePages();
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calculateMaxPagesToShow();
    this.updatePages();
  }

  calculateMaxPagesToShow(): void {
    const screenWidth = window.innerWidth;
    if (screenWidth < 576) {
      this.maxPagesToShow = 3;
    } else if (screenWidth < 768) {
      this.maxPagesToShow = 5;
    } else {
      this.maxPagesToShow = 7;
    }
    this.updatePages();
  }

  updatePages(): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

    const half = Math.floor(this.maxPagesToShow / 2);
    const maxSidePages =  Math.floor(this.maxPagesToShow / 2 ); // Количество страниц слева и справа от текущей страницы

    let start = Math.max(this.currentPage - maxSidePages, 1);
    let end = Math.min(this.currentPage + maxSidePages, this.totalPages);

    // Если отображаемых страниц меньше, чем допустимо, корректируем границы
    if (end - start + 1 < this.maxPagesToShow) {
      if (this.currentPage <= half) {
        end = Math.min(start + this.maxPagesToShow - 1, this.totalPages);
      } else if (this.currentPage + half >= this.totalPages) {
        start = Math.max(this.totalPages - this.maxPagesToShow + 1, 1);
      }
    }

    this.pages = [];
    for (let i = start; i <= end; i++) {
      this.pages.push(i);
    }
  }

  onPrevious(): void {
    if (this.currentPage > 1) {
      this.pageChanged.emit(this.currentPage - 1);
    }
    this.updatePages();
  }

  onNext(): void {
    if (this.currentPage < this.totalPages) {
      this.pageChanged.emit(this.currentPage + 1);
    }
    this.updatePages();
  }

  onPageClick(page: number): void {
    this.pageChanged.emit(page);
  }

  onNextEventMore() {
    if (this.currentPage < this.totalPages) {
      this.pageMore.emit(this.currentPage + 1);
    }
  }


  onLeftEllipsisClick(): void {
    const newPage = Math.max(this.pages[0] - this.maxPagesToShow, 1);
    this.pageChanged.emit(newPage);
    this.updatePages();
  }

  onRightEllipsisClick(): void {
    const newPage = Math.min(this.pages[this.pages.length - 1] + 1, this.totalPages);
    this.pageChanged.emit(newPage);
    this.updatePages();
  }
}
