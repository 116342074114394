<app-frame-page [titlePage]="'navigations.org' | translate" class="decorationFull">
  <ng-container>
    <div class="content-wrapper--middle" *ngIf="main">
      <div class="org-list">
        <div class="org-item">
          <img class="logo" [src]="environment.imageUrl + main.get_thumb" alt="Союз организаций атомной отрасли «Атомные города»">
          <div class="content">
            <h4 class="h4" [innerHTML]="main.title"></h4>
            <div [innerHTML]="main.text"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper--small" *ngIf="projList.length">
      <h2 class="h3">{{'pages.main.projects' | translate}}</h2>
      <div class="org-list">
        <div class="org-item alt" *ngFor="let item of projList">
          <img class="logo" [src]="environment.imageUrl + item.get_thumb" alt="">
          <div class="content">
            <h4 class="h4" [innerHTML]="item.title"></h4>
            <div [innerHTML]="item.text"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-frame-page>
