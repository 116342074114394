import { Component, OnDestroy } from '@angular/core';
import { ApiService } from "../../service/api.service";
import { EmblaOptionsType } from 'embla-carousel';
import { IEvent, IFaq, IMediaPartners, IMediaProjects, IVideoMainPageAdvantages } from '../../types';
import { environment } from '../../../environments/environment';
import { IPerson } from '../../types/response/person.interface';
import { PropsService } from '../../service/props.service';
import { IReview } from '../../types/response/review.interface';
import {FaqService} from "../../service/faq.service";
import {ActivatedRoute} from "@angular/router";
import {AuthModalService} from "../../service/auth-modal.service";
import {AuthService} from "../../service/auth.service";

@Component({
  selector: 'app-page-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnDestroy {

  eventsList: IEvent[] = [];
  projectsList: IMediaProjects[] = [];
  partnersLogo: IMediaPartners[] = [];
  questionList: IFaq[] = [];
  personList: IPerson[] = [];
  reviewList: IReview[] = [];
  videoNotLoad = true;

  partnersLogoEmblaOptions: EmblaOptionsType = {
    loop: true,
    align: 'start',
  };
  reviewEmblaOptions: EmblaOptionsType = {
    loop: true,
    align: 'center',
  };
  videoAdvantages!: IVideoMainPageAdvantages;

  days$ = this.propsService.days$;
  min$ = this.propsService.minutes$;
  hour$ = this.propsService.hours$;
  timerInit$ = this.propsService.timerInit$;
  isAuthBtn$ = this.authService.isAuthenticated$;
  // seconds$ = this.propsService.seconds$;
  constructor(private apiService: ApiService,
              private propsService: PropsService,
              private authModal: AuthModalService,
              private route: ActivatedRoute,
              private authService: AuthService,
              public faqService: FaqService) {
    this.apiService.getQuiz().subscribe(x => {
      this.isDictantAvailable = x[0].published;
    })

    this.apiService.getEventsMain().subscribe(events => {
      this.eventsList = events;
    });


    this.apiService.getProjects().subscribe(projects => {
      this.projectsList = projects.slice(1, projects.length);
    });

    this.apiService.getPartners().subscribe(partners => {
      this.partnersLogo = [...partners, ...partners, ...partners, ...partners];
    });

    this.apiService.getFaqMain().subscribe(question => {
      this.faqService.init(question);
      this.questionList = question;
    });

    this.apiService.getPerson().subscribe(persons => {
      this.personList = persons;
    });

    this.apiService.getMainVideoAdvantages().subscribe(videos => {
      this.videoAdvantages = videos[0];
    });

    this.apiService.getMainResponses().subscribe(reviews => {
      this.reviewList = reviews;
    });

    this.propsService.headerNotFixed.next(true);
  }

  ngOnDestroy() {
    this.propsService.headerNotFixed.next(false);
  }

  protected readonly environment = environment;
  isDictantAvailable = false;

  nextWindow() {
    // @ts-ignore
    document.querySelector('html').scrollTo({top: window.innerHeight, behavior: "smooth"});
  }

  videoStart() {
    this.videoNotLoad = false;
  }

  setOpen(id: number) {
    this.faqService.setOpen(id);
  }

  reg() {
    this.authModal.type$.next('reg');
    this.authModal.isOpen$.next(true);
  }
}
