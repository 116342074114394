<header class="header" [ngClass]="{'menu--open': stateMenu}">
  <div class="header-wrapper content-wrapper">
    <div class="header__logo" (click)="closeMenu()" routerLink=""></div>
    <nav class="header-nav">
      <ul class="navList">
        <li class="navList__item" routerLinkActive="active"><a href="#" routerLink="about" (click)="closeMenu()">{{ 'navigations.projects' | translate }}</a></li>
        <!--<li class="navList__item navList__item&#45;&#45;desktop" routerLinkActive="active"><a routerLink="dictation" (click)="closeMenu()">диктант</a></li>-->
        <li class="navList__item" routerLinkActive="active"><a href="#" routerLink="events" (click)="closeMenu()">{{ 'navigations.events' | translate }}</a></li>
        <li class="navList__item navList__item--mobile" routerLinkActive="active"><a routerLink="partners" (click)="closeMenu()">{{ 'navigations.partners' | translate }}</a></li>
        <li class="navList__item  navList__item--mobile" routerLinkActive="active"><a routerLink="reviews" (click)="closeMenu()">{{ 'navigations.reviews' | translate }}</a></li>
        <li class="navList__item" routerLinkActive="active"><a routerLink="faq" (click)="closeMenu()">{{ 'navigations.question' | translate }}</a></li>
        <li class="navList__item  navList__item--mobile" routerLinkActive="active"><a routerLink="media-docs" (click)="closeMenu()">{{ 'navigations.media' | translate }}</a></li>
      </ul>
      <div class="mobile-info">
        <ui-button (click)="bviOpen()">{{ 'btn.see' | translate }}</ui-button>
        <div class="contacts">
          <div class="address">
            <div class="caption">{{ 'footer.contactData' | translate}}</div>
            <div class="value"><a href="https://yandex.ru/maps/-/CDgCmRKp" target="_blank" [innerHTML]="'footer.address' | translate">Москва, <br>1-я Тверская-Ямская д. 25с2</a></div>
          </div>
          <div class="address">
            <div class="caption">{{ 'footer.writeToUse' | translate}}</div>
            <div class="value"><a href="mailto:info@atomdiktant.ru">info&#64;atomdiktant.ru</a></div>
          </div>
        </div>
        <div class="footer-info">
          <div class="political">© «{{ 'copyright' | translate }}» 2018—2024</div>
          <a routerLink="political" (click)="closeMenu()">{{ 'navigations.political'  | translate }}</a>
        </div>
      </div>
    </nav>
    <div class="actions">
      <div class="icon" (click)="bviOpen()"></div>
      <a (click)="switchLanguage()" *ngIf="!(dictationPage$ | async)" class="lang">{{langOther$ | async}}</a>
      <ui-button (click)="login()" *ngIf="!(isAuth | async)">{{ 'btn.enter' | translate }}</ui-button>
      <div class="icon-user" routerLink="profile" *ngIf="isAuth | async"></div>
      <div class="menu" [ngClass]="{'menu--open': stateMenu}" (click)="setMenu()">
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</header>
