import { Component, Input } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { IFiles } from '../../types/response/files.interface';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-files',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
  ],
  templateUrl: './files.component.html',
  styleUrl: './files.component.scss'
})
export class FilesComponent {
  @Input() files: IFiles[] = [];

  download(link: string) {
    window.location.href = link;
  }

  protected readonly environment = environment;
}
