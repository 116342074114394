import {AbstractControl} from "@angular/forms";

export function validatePhoneNumberLength15(ctrl: AbstractControl) {
  if (ctrl.value) {
    const phoneNumber = ctrl.value.replace(/[^0-9]/gim, '');
    if (phoneNumber.length > 15) {
      return { invalidPhoneNumberLength: true };
    }
  }
  return null;
}
