<div
  class="embla"
  emblaCarousel
  [options]="options"
  [subscribeToEvents]="subscribeToEvents"
  (emblaChange)="onEmblaChanged($event)"
  [plugins]="plugins"
>
  <div class="embla__container">
    <ng-content></ng-content>
  </div>
</div>
<div class="nav-arrow" *ngIf="arrowVisible">
  <ui-button typeButton="slider-action"
             class="nav-action nav-action--prev"
             [isDisabled]="prevDisabled" (click)="prevSlide()">Назад</ui-button>
  <ui-button typeButton="slider-action"
             class="nav-action nav-action--next"
             [isDisabled]="nextDisabled" (click)="nextSlide()">Вперед</ui-button>
</div>
