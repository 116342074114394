import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PropsService {
  headerNotFixed = new BehaviorSubject(false);
  stateMenu$ = new BehaviorSubject(false);
  timerInit$ = new BehaviorSubject(false);
  deadline = new Date('09/01/2025');
  days$ = new BehaviorSubject<string>('0');
  hours$ = new BehaviorSubject<string>('0');
  minutes$ = new BehaviorSubject<string>('0');
  // seconds$ = new BehaviorSubject<string>('0');
  diff = 0;
  constructor() {
    setTimeout(x => {
      this.timerInit();
    }, 1000)
  }

  timerInit() {
    this.deadline.setHours(8, 0, 0, 0);
    this.diff = this.deadline.getTime() - new Date().getTime();
    if (this.diff >= 0) {
      interval(1000).subscribe(x => {
        this.timerTick()
      });
    }
  }

  timerTick() {
    this.diff = this.deadline.getTime() - new Date().getTime();
    const diff = this.diff;
    const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
    const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
    const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
    const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
    this.days$.next(String(days < 10 ? '0' + days : days));
    this.hours$.next(String(hours < 10 ? '0' + hours : hours));
    this.minutes$.next(String(minutes < 10 ? '0' + minutes : minutes));

    if (this.diff > 0) {
      this.timerInit$.next(true);
    } else {
      this.timerInit$.next(false);
    }
    // this.seconds$.next(String(seconds < 10 ? '0' + seconds : seconds));
    return;
  }

}
