import {Injectable} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router} from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import {AuthService} from "../service/auth.service";
@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService,
              private http: HttpClient,
              private router: Router) {
  }

  isApiResponse(url: string) {
    return url.indexOf(environment.apiUrl) + 1;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((this.auth.isAuthenticated$.value || this.auth.isAuthenticated())) {
      req = req.clone( {
        setHeaders: {
          'Authorization': 'Bearer ' + this.auth.accessToken
        }
      });
    }
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            /*this.dictant-main.logout();
            this.router.navigate(['/login'], {
              queryParams: {
                authFailed: true
              }
            });*/
          }
          return throwError(error);
        })
      );
  }
}
