<app-frame-page *ngIf="loaded" [titlePage]="event.title" class="decorationFull">
  <ng-container>
    <div class="wrapper--small">
      <div class="pretitle" *ngIf="event.intro" [innerHTML]="event.intro">{{event.intro}}</div>
      <div class="gallery" *ngIf="event.get_published_images.length">
          <app-gallery-slider [images]="event.get_published_images"></app-gallery-slider>
      </div>
      <div class="html-custom" [innerHTML]="event.body">
      </div>
      <app-social></app-social>
    </div>
  </ng-container>
  <ng-container headaction>
    <ui-button type="secondary" routerLink="/events">{{ 'pages.eventsDetails.action' | translate }}</ui-button>
  </ng-container>
</app-frame-page>
