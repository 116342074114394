import {Component, Inject, Input, OnInit, Self} from '@angular/core';
import { UiModule } from '../../ui/ui.module';
import { NgForOf, NgIf } from '@angular/common';
import {AuthModalService} from "../../service/auth-modal.service";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ApiService} from "../../service/api.service";
import {UIInputPhoneRuComponent} from "../ui-input-phone-ru/ui-input-phone-ru.component";
import {UIInputComponent} from "../ui-input/ui-input.component";
import {UIInputPhoneModule} from "../ui-input-phone/ui-input-phone.module";
import {AuthService} from "../../service/auth.service";
import {UiTimerButtonModule} from "../ui-timer-button/ui-timer-button.module";
import {Observable, Subject, takeUntil} from "rxjs";
import {IRecoveryPassword} from "../../types/request/auth.request";
import {TranslateModule} from "@ngx-translate/core";
import {DestroyService} from "../../service/destroy.service";

@Component({
  selector: 'app-recovery-password-main-modal',
  templateUrl: './recovery-password-main-modal.component.html',
  standalone: true,
  imports: [
    UiModule,
    NgIf,
    NgForOf,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    UIInputPhoneRuComponent,
    UIInputComponent,
    UIInputPhoneModule,
    UiTimerButtonModule,
    TranslateModule,
  ],
  providers: [DestroyService],
  styleUrls: [ './recovery-password-main-modal.component.scss' ],
})
export class RecoveryPasswordMainModalComponent implements OnInit {

  @Input() type: 'reg' | 'auth' | 'code' | 'recovery' = 'auth';

  repeatTimer$: Subject<void> = new Subject();

  formRecovery = new FormGroup({
    password: new FormControl('', [Validators.required]),
    password2: new FormControl('', [Validators.required]),
  });
  errorServer = [];


  constructor(private authModalService: AuthModalService,
              private apiService: ApiService,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              @Self() @Inject(DestroyService) private destroy$: Observable<void>) { }

  ngOnInit(): void {
    this.formRecovery.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(req => {
      this.errorServer = [];
    });
  }



  close() {
    this.authModalService.close();
    this.errorServer = [];
  }

  submitRecovery() {
    if (this.formRecovery.invalid) {
      this.formRecovery.markAllAsTouched();
      this.formRecovery.updateValueAndValidity();
      return;
    }
    const form = this.formRecovery.getRawValue();
    const data: IRecoveryPassword = {
      token: this.authModalService.isOpenRecoveryPasswordModal$.getValue(),
      password: form.password || '',
      password2: form.password2 || ''
    };
    this.apiService.newChangePassword(data).subscribe(req => {
       this.authService.setToken(req);
       this.authService.profileInit();
       this.router.navigateByUrl('profile');
       this.authModalService.close();
       this.errorServer = [];
    }, error => {
     if (error.error.errors) {
       this.errorServer = error.error.errors;
     }
   });

  }

  navigateUrl(url: string) {
    this.authModalService.close();
    this.errorServer = [];
    this.router.navigateByUrl(url);
  }

  getControl(name: string): FormControl {
    return this.formRecovery.get(name) as FormControl;
  }
}
