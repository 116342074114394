<app-frame-page [titlePage]="'pages.reviews.title' | translate" class="decorationFull">
  <ng-container *ngIf="totalItems" headtitle>{{'pages.reviews.all' | translate }} {{totalItems}} {{ totalItems | declensionDate: 'review'}}</ng-container>
  <ng-container headfooter>
    <!--<div class="head-action">
      <ui-button (click)="openModal()">Оставить свой отзыв</ui-button>
    </div> TODO modal-->
  </ng-container>
  <ng-container>
    <div class="reviews-list">
      <app-review-item [default]="true"  *ngFor="let item of reviewsList" [active]="false" [address]="item.geo" [name]="item.title">
          <div [innerHTML]="item.text" ></div>
      </app-review-item>
    </div>
  </ng-container>
  <ng-container footer>
    <app-pagination
      *ngIf="paginationReady && itemsPerPage <= totalItems"
      [totalItems]="totalItems"
      [itemsPerPage]="itemsPerPage"
      [currentPage$]="currentPage$"
      (pageChanged)="onPageChanged($event)"
      (pageMore)="onPageMore($event)"
    ></app-pagination>
  </ng-container>

</app-frame-page>
