import { Component } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { IFiles } from '../../types/response/files.interface';

@Component({
  selector: 'app-page-about',
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {
  filesList: IFiles[] = [];
  constructor(private apiService: ApiService) {
    this.apiService.getFiles().subscribe(files => {
      this.filesList = files;
    })
  }
}
