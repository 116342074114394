<div class="bg" (click)="close()"></div>
<div class="custom-modal">
  <div class="content">
    <div class="close" (click)="close()"><div class="icon-close"></div></div>
    <div class="content-wrapper">
        <div>
          <h2 class="h3 auth-title-section-modal" [innerHTML]="'restoreModal.title' | translate">Восстановление <br>доступа</h2>
          <div class="pretitle" [innerHTML]="'restoreModal.pretitle' | translate">Придумайте новый пароль</div>
        </div>
        <form class="fiels-list" [formGroup]="formRecovery">
          <ul class="field-list">
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.newPassword' | translate" formControlName="password" [Mcontrol]="getControl('password')" [isRequired]="true" type="password" [placeholder]="'inputs.label.newPassword' | translate"></ui-input>
            </li>
            <li class="field-list__item">
              <ui-input [label]="'inputs.label.confirm' | translate" formControlName="password2" [Mcontrol]="getControl('password2')" [isRequired]="true" type="password" [placeholder]="'inputs.label.password2' | translate"></ui-input>
            </li>
          </ul>
          <ui-button type="primary" [isDisabled]="formRecovery.invalid" (click)="formRecovery.valid && submitRecovery()">{{'btn.save' | translate}}</ui-button>
          <div class="error-server" *ngFor="let item of errorServer">{{item}}</div>
        </form>
    </div>
  </div>
</div>
