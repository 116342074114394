import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'ui-timer-button',
  template: `
        <a
            *ngIf="!smsCodeTimer"
            class="button-link-action"
            (click)="click()">
            {{ btnText }}
        </a>
        <div *ngIf="smsCodeTimer">
            {{'timer.other' | translate}}{{ smsCodeTimer | secondsToMinutes }}.
        </div>
    `,
  styleUrl: 'ui-timer-button.component.scss'
})

export class UITimerButtonComponent implements OnInit, OnDestroy {
  @Input() startTimerInit = true;

  @Input()
  timeTimer = 60;

  @Input()
  btnText = 'Получить новый код';

  @Input()
  repeaterTimer$!: Subject<void>;


  @Output()
  emitClick: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  disabled = false;

  smsCodeTimer = 0;

  countdown$: Subject<number> = new Subject<number>();

  private unsubscriber$: Subject<void> = new Subject<void>();
  private interval: any;
  constructor() { }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngOnInit(): void {
    if (this.startTimerInit) {
      this.resetInterval();
    }
    this.repeaterTimer$.pipe(takeUntil(this.unsubscriber$)).subscribe(() => this.resetInterval());
  }

  click() {
    this.emitClick.emit();
  }
  resetInterval() {
    this.smsCodeTimer = this.timeTimer;
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.smsCodeTimer--;
      if (this.smsCodeTimer === 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }
}
