import {Component, OnInit} from '@angular/core';
import { ApiService } from '../../service/api.service';
import { IMediaProjects } from '../../types';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-organizers',
  templateUrl: './organizers.component.html',
  styleUrl: './organizers.component.scss'
})
export class OrganizersComponent implements OnInit {
  main!: IMediaProjects;
  projList: IMediaProjects[] = []
  constructor(private apiService: ApiService) {
    /*this.apiService.getProjects().subscribe(proj => {
      this.main = proj[0];
      this.projList = proj.slice(1, proj.length);
    });*/
  }

  ngOnInit() {
    this.apiService.getProjects().subscribe(proj => {
      this.main = proj[0];
      this.projList = proj.slice(1, proj.length);
    });
    window.scrollTo(0, 0)
  }

  protected readonly environment = environment;
}
