import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import {Router, RouterLink} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-router.module";
import {PagesModule} from "./pages/pages.module";
import {FooterComponent} from "./components/footer/footer.component";
import {HeaderComponent} from "./components/header/header.component";
import {UiModule} from "./ui/ui.module";
import {BrowserModule} from "@angular/platform-browser";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { LangInterceptor } from './interceptor/lang.interceptor';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { GalleryImageComponent } from './components/gallery-image/gallery-image.component';
import { CookieReqComponent } from './components/cookie-req/cookie-req.component';
import { DeclinsionPipe } from './pipe/declinsion.pipe';
import {AuthModalComponent} from "./components/auth-modal/auth-modal.component";
import {AuthInterceptor} from "./interceptor/auth.interceptor";
import {
  RecoveryPasswordMainModalComponent
} from "./components/recovery-password-main-modal/recovery-password-main-modal.component";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {LocaleService} from "./service/locale.service";
import {MetrikaModule} from "ng-yandex-metrika";
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEn, 'en');


import dayjs from 'dayjs';
import 'dayjs/locale/ru'; // Импортируем русскую локализацию
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import {FlatpickrModule} from "angularx-flatpickr";
import * as Sentry from "@sentry/angular";

dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.locale('ru');



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    RouterLink,
    AppRoutingModule,
    PagesModule,
    UiModule,
    HttpClientModule,
    GalleryImageComponent,
    CookieReqComponent,
    AuthModalComponent,
    RecoveryPasswordMainModalComponent,
    FlatpickrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MetrikaModule.forRoot([
    {
      id: 97723831,
      clickmap: true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor: true,
    }])
  ],
  providers: [
    DeclinsionPipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  {
    provide: LOCALE_ID,
    deps: [LocaleService],
    useFactory: (localeService: LocaleService) => localeService.getLocale()
  },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LangInterceptor,
        multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
