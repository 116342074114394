<div
  class="content-wrapper decoration decoration--image-right-2"
  style="--decoration-top-position: -436px"
>
  <h2 class="section-title section-center">{{ 'pages.main.supported' | translate}}</h2>

  <app-embla-slider
    *ngIf="personList.length"
    [options]="eventsEmblaOptions"
    [arrowVisible]="true"
  >
    <div class="embla__slide" *ngFor="let slide of personList">
      <div class="embla__image">
        <div class="image-wrapper">
          <img
            *ngIf="!!slide.get_thumb"
            [src]="environment.imageUrl + slide.get_thumb"
            alt=""
          />
        </div>
      </div>
      <div class="embla__description" *ngIf="!!slide.title || !!slide.position">
        <div class="caption">{{ slide.title }}</div>
        <div class="description">{{ slide.position }}</div>
      </div>
    </div>
  </app-embla-slider>
</div>
