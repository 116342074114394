import {Component, Input, ViewChild} from '@angular/core';
import {UiModule} from "../../ui/ui.module";
import {EmblaCarouselDirective} from "embla-carousel-angular";
import {IEvent, IEventDetail, IEventGallery} from "../../types";
import {NgForOf, NgIf} from "@angular/common";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-gallery-slider',
  standalone: true,
  imports: [
    UiModule,
    EmblaCarouselDirective,
    NgForOf,
    NgIf
  ],
  templateUrl: './gallery-slider.component.html',
  styleUrl: './gallery-slider.component.scss'
})
export class GallerySliderComponent {
  @Input() images: IEventGallery[] = [] // Массив изображений
  selectedIndex: number = 0; // Индекс выбранного изображения

  @ViewChild('embla') embla: EmblaCarouselDirective | undefined;

  constructor() {}

  selectImage(index: number): void {
    this.selectedIndex = index;
  }

  nextImage(): void {
    this.selectedIndex = (this.selectedIndex + 1) % this.images.length;
    this.embla?.scrollTo(this.selectedIndex);
  }

  prevImage(): void {
    this.selectedIndex = (this.selectedIndex - 1 + this.images.length) % this.images.length;
    this.embla?.scrollTo(this.selectedIndex);
  }

  protected readonly environment = environment;
}
