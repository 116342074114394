import { Component, EventEmitter, Output } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { UiModule } from '../../ui/ui.module';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-cookie-req',
  templateUrl: './cookie-req.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    UiModule,
    TranslateModule,
  ],
  styleUrl: './cookie-req.component.scss'
})
export class CookieReqComponent {

  @Output() reqCookie = new EventEmitter<boolean>();
  req() {
    this.reqCookie.emit(true);
  }
}
