import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {map, Observable, of} from 'rxjs';
import {AuthService} from "../service/auth.service";
import {DictantService} from "../service/dictant.service";
import {ApiService} from "../service/api.service";

@Injectable({
  providedIn: 'root'
})
export class DictantAvailableGuard implements CanActivate, CanActivateChild {
  constructor(
    private http: ApiService,
    private router: Router,
    private dictantService: DictantService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    // @ts-ignore
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.http.getQuiz().pipe(map(response => {
      if (!response[0].published) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }))
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    // @ts-ignore
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.http.getQuiz().pipe(map(response => {
      if (!response[0].published) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }))
  }

}
