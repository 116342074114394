<div class="bg" (click)="close()"></div>
<div class="close" (click)="close()"><div class="icon-close"></div></div>
<div class="custom-modal">
  <div class="arrow arrow-prev" *ngIf="images.length > 1 || videos.length > 1" (click)="prevImage()">
    <ui-button class="action-prev" typeButton="slider-action"></ui-button>
  </div>
  <div class="gallery-image">
    <div class="gallery-image__list">
      <div class="gallery-image__item">
        <img *ngIf="type === 'image'" [src]="images[currentIndex].link"
             [alt]="images[currentIndex].title"
             class="image">
        <iframe *ngIf="type === 'video'" [src]="videos[currentIndex].link" frameBorder="0" allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media" frameborder="0" scrolling="no" allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="arrow arrow-next"  *ngIf="images.length > 1 || videos.length > 1" (click)="nextImage()">
    <ui-button typeButton="slider-action"></ui-button>
  </div>
</div>
