import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-review-item',
  standalone: true,
  templateUrl: './review-item.component.html',
  styleUrl: './review-item.component.scss'
})
export class ReviewItemComponent {
  @Input() address = '';
  @Input() name = '';
  @Input() active = false;
  @Input() default = false;
}
