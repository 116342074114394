import { Component, Input } from '@angular/core';
import {EmblaSliderComponent} from "../embla-slider/embla-slider.component";
import {NgForOf, NgIf} from "@angular/common";
import { EmblaOptionsType } from 'embla-carousel';
import {SectionComponent} from "../../components/section/section.component";
import { IPerson } from '../../types/response/person.interface';
import { environment } from '../../../environments/environment';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-partner-slider',
  standalone: true,
  imports: [
    EmblaSliderComponent,
    NgForOf,
    NgIf,
    SectionComponent,
    TranslateModule
  ],
  templateUrl: './partner-slider.component.html',
  styleUrl: './partner-slider.component.scss'
})
export class PartnerSliderComponent {

  @Input() personList: IPerson[] = [];

  eventsEmblaOptions: EmblaOptionsType = {
    align: 'start',
    loop: true,
    startIndex: 0,
    breakpoints: {
      '(max-width: 1360px)': {
        align: 'center',
        loop: true,
        startIndex: 2,
      }
    }
  };

  protected readonly environment = environment;
}
