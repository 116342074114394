import { Component, ElementRef, Host, Inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { conformToMask } from 'angular2-text-mask';
import { validatePhoneNumberLength15 } from './phone-number-length.validator';
import { UIBaseControlComponent } from '../ui-base-control/ui-base-control.component';
import { NgControl, NgModel, ValidationErrors } from '@angular/forms';
import { UI_INPUT_VALIDATION_ERROR_TOKEN } from '../ui-base-control/ui-input-validation-error-token';
import type {MaskitoOptions} from '@maskito/core';
import mask from './phone-mask';

@Component({
  selector: 'ui-input-phone',
  styleUrls: ['./ui-input-phone.component.scss'],
  templateUrl: './ui-input-phone.component.html',
})
export class UIInputPhoneComponent extends UIBaseControlComponent implements OnInit {
  // TODO: add explicit constructor
  @ViewChild('input', { static: true })
  input!: ElementRef;
  @Input() onlyMobile = true;
  override validators = [validatePhoneNumberLength15];
  @Input() lang = 'ru';

  readonly options: MaskitoOptions = mask;

  @Optional()
  @Host()
  @Inject(UI_INPUT_VALIDATION_ERROR_TOKEN)
  override customErrors: ValidationErrors;

  constructor(
    @Self()
    @Optional()
    ngControl: NgControl,
    @Optional()
    @Host()
    @Inject(UI_INPUT_VALIDATION_ERROR_TOKEN)
    customErrors: ValidationErrors
  ) {
    super(ngControl, customErrors);
    this.customErrors = customErrors;
  }

  phonePipe = function (cValue: string, config: any) {
    if (cValue.indexOf('+8') !== -1) {
      setTimeout(() => {
        // @ts-ignore
        this.safeSetSelection(config.currentCaretPosition + 3);
      });
      return cValue.replace('+8', '+7');
    }
    return cValue;
  }.bind(this);

  phoneMaskFunction = (v: string) => {
    const chars = v.replace(/[^+=0-9]/g, '').split('');

    let postMask = '';
    /*const mobileMask = [ /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
    const areaPhoneMask = [ /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    let prefixMask: (RegExp | string)[] = [/8/, ' ', '(', /[39]/];

    if (chars[1] && /[3]/.test(chars[1])) {
      return prefixMask = ['8', ' ', '(', /[3]/, /[09]/, /[125]/, /[2]/, ')', ' ', ...areaPhoneMask];
    } else {
      return ['8', ' ', '(', /[9]/, /\d/, /\d/,')', ' ', ...mobileMask]
    }*/
    const mobileMask = [ /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
    const areaPhoneMask = [ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    let prefixMask: (RegExp | string)[] = [/8/, /[39]/];
    if (this.onlyMobile) {
      return ['8', ' ', '(' ,/[9]/, /\d/, /\d/, ')', ' ', ...mobileMask];
    }
    if (chars[1] && /[3]/.test(chars[1])) {
      return prefixMask = ['8', /[3]/, /[09]/, /[125]/, /[2]/, ...areaPhoneMask];
    } else {
      return ['8' ,/[9]/, /\d/, /\d/, ...mobileMask]
    }
    /*return [...prefixMask, ...baseMask];*/
  }

  /*
  83952xxxxxx
  83012xxxxxx
  83022xxxxxx
  89xxxxxxxxx
  */

  private onInputChange() {
    const input = this.input.nativeElement;
    // get value from text area
    const newValue = input.value;

    // update the form
    this.onChange(newValue);
  }


  override ngOnInit() {
    super.ngOnInit();

    // support for value change by [(ngModel)]
    if (this.ngControl instanceof NgModel) {
      const inputElement = this.input.nativeElement;
      inputElement.onchange = () => this.onInputChange();
      inputElement.onkeyup = () => this.onInputChange();
    }
    if (this.formControl.value) {
      const conformedValue = conformToMask(this.formControl.value, this.phoneMaskFunction, { pipe: this.phonePipe.bind(this) }).conformedValue;
      this.formControl.setValue(conformedValue, { emitEvent: false });
    }
  }

  safeSetSelection(selectionPosition: number) {
    this.input.nativeElement.setSelectionRange(selectionPosition, selectionPosition);
  }
}
